@import url(https://fonts.googleapis.com/css?family=Space+Mono:400,400i,700,700i&display=swap);
body {
  margin: 0;
  padding: 0;
  background-color: #fff; }

* {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */ }

.canvas {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0; }
  .canvas * {
    z-index: -1; }

.text {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  line-height: 6vw;
  z-index: 99 !important;
  font-family: 'Space Mono', monospace;
  text-align: center;
  pointer-events: none;
  color: #fff;
  mix-blend-mode: exclusion; }

.hole {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 200px;
  z-index: -1;
  -webkit-transition-duration: .2s;
          transition-duration: .2s; }
  .hole img {
    width: 100%;
    display: block;
    mix-blend-mode: lighten; }

.bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: -2; }

.switch {
  z-index: 9999999;
  width: 30px;
  position: fixed;
  left: 30px;
  bottom: 30px; }
  .switch img {
    width: 100%; }

.dullversion {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99999;
  overflow: scroll; }

.card {
  background-color: #000;
  color: white;
  padding: 40px;
  font-family: 'Space Mono', monospace;
  font-size: 24px; }

.card:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #000; }

.info {
  background-color: #fff;
  color: #fff;
  text-align: center;
  padding: 120px;
  font-family: 'Space Mono', monospace;
  font-size: 24px;
  background-size: 200% auto;
  background-image: -webkit-linear-gradient(0deg, red, yellow, green, blue, purple, red);
  -webkit-animation: shine 3s linear infinite;
          animation: shine 3s linear infinite; }

@media screen and (max-width: 900px) {
  .info {
    padding: 200px 20px 300px !important; }
  .hole {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 150px;
    z-index: -1; }
  .text {
    font-size: 10vw !important;
    line-height: 12vw;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  .what {
    display: none; } }

.info p {
  mix-blend-mode: exclusion; }

@-webkit-keyframes shine {
  to {
    background-position: 200% center; } }

@keyframes shine {
  to {
    background-position: 200% center; } }

a {
  text-decoration: none !important;
  color: inherit !important; }

.what {
  font-family: 'Space Mono', monospace;
  font-size: 24px;
  padding: 20px; }

.holing {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-size: 200% auto;
  background-image: -webkit-linear-gradient(0deg, red, yellow, green, blue, purple, red);
  -webkit-animation: shine .25s linear infinite;
          animation: shine .25s linear infinite; }

